








import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'

@Component({
  components: {
    PageLayout
  }
})
export default class PreTest extends Vue {
  private lessHeight = false
  private mounted (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('less-height', () => {
        this.lessHeight = true
      })
    }
  }

  private destroyed (): void {
    if (window.innerWidth < 767) {
      this.$root.$off('less-height')
    }
  }
}
